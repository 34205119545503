/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import { SEO } from '../../components/seo';
import { Trans, useTranslation } from 'react-i18next';
import { constants } from '../../constants';
import { Link } from 'gatsby-plugin-react-i18next';

const IndexPage = () =>
{
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t('Thank You')}
        noindex={true}
      ></SEO>
      <Layout>
      <div className="py-16 sm:py-16 clip-path-curved bg-slate-900">

      </div>
        <div className="bg-white text-center">
          <div className="mx-auto max-w-2xl py-12 px-6 lg:py-16 lg:px-8">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl bg-gradient-to-r from-emerald-500 via-sky-500 to-indigo-500 bg-clip-text  text-transparent">
              Thank you!
            </h2>
            <p className="my-4 text-xl font-bold tracking-tight font-display">
              You should have received an email containing the download link. If not, please <a className="underline text-sky-600" href={constants.links.contact}>contact us</a>.
            </p>
          </div>
      </div>

      <img height="1" width="1" style={{ display: 'none' }} alt="" src="https://px.ads.linkedin.com/collect/?pid=4626138&conversionId=10947474&fmt=gif" />
      <img height="1" width="1" style={{display: 'none'}}
        src="https://www.facebook.com/tr?id=1582510435599094&ev=Purchase&cd[value]=19.99&cd[currency]=USD"
      />
      </Layout>
    </>
  );
};

export default IndexPage;
